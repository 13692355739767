import { js } from "./utils";
import { kb } from "./knobster";

export const propertiesView = () => {
  const render = (element, target) => {
    const layer = element.layer;
    const titleCaseName = js.titleCase(layer.name);
    const titleElement = (new DOMParser()).parseFromString(
      `<div class="${js.classes.title}">${titleCaseName}:</div>`,
      "text/html",
    );
    for (const child of target.children) {
      child.remove();
    }
    target.appendChild(titleElement.body.firstChild);
  };
  return {
    render: render,
  };
};
