import { js } from "./utils.js";
import { kb } from "./knobster.js";
import { propertiesView } from "./propertiesView.js";

export class KnobMaker extends HTMLElement {
    static styleSheet = `
    <style>
    .container{
        margin: 0.5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: top;
        gap: 1.25rem;
    } 

    knobster-knob{
        margin: 0.5rem;
    }

    .target{
        width: 100%;
        text-align: center;
    }

    .card {
        margin-top: 1rem;
        border: solid #c0c0c0 0.25rem;
        border-radius: .75rem;
        justify-content: center;
    }

    .card-title{
        border-bottom : solid #c0c0c0 0.25rem;
        margin:0;
        padding: 0.25rem 1.5rem;
    }

    .kb-list {
        border-radius: .75rem;
        padding: 0.2rem;
        margin: 0.2rem 0.5rem ;
    }

    .kb-list-item {
        background-color: white;
        color: black;
        padding: .25rem .5rem;
        margin: .rem .3rem;
        border-radius: .5rem;
    }
        
    .kb-list-item:hover {
        background-color: lightgrey;
    }

    .kb-list-item.active {
        background-color: rgb(96, 96, 96);
        color: white;
    }

    .kb-list-item.active > button.btn-add{
        background-color: rgb(0, 160, 0);
        color:black;
    }

    button {
        display: table-cell;
        vertical-align: middle;
        padding: 0.2rem 0.3rem;
        font-size: 1.5rem;
        border-radius: 50%;
        width:2rem;
        height:2rem;
    }

    .btn-add {
        color: white;
        background-color: rgb(0, 128, 0);
    }

    .btn-add:hover {
        background-color: rgb(0, 160, 0);
        color:black;
    }

    .btn-del:{
        background-color: rgb(64, 0, 0);
    }

    .btn-del:hover {
        background-color: rgb(0, 156, 0);
        color:black;
    }

    .row{
        width:100%;
        display: flex;
        flex-wrap: wrap;
    }

    ${js.selector.colKnob}{
        margin: 0 .5rem;
    }

    ${js.selector.title}{
        margin: 0 .5rem;
    }

    ${js.selector.col33}{
       width:25%;
       flex: 1 1 8rem;
    }
</style>
`;
    available;
    display;
    knob;
    layers;
    properties;

    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
    }

    connectedCallback() {
        this.shadowRoot.innerHTML = KnobMaker.styleSheet;
        this.#render();
        this.#addDefaults();
    }

    #addDefaults() {
        const makeDefaults = () => {
            this.knob.addLayer(kb.layers.circle)
                .classList.remove("active");
            this.knob.addLayer(kb.layers.line)
                .classList.remove("active");
            this.knob.update(50);
        }
        if (this.knob && this.knob.addLayer) {
            makeDefaults();
        } else {
            setTimeout(makeDefaults, 10);
        }
    }
    #render() {
        const container = js.createElement(js.tag.div, this.shadowRoot, js.classes.container);
        const row1 = js.createElement(js.tag.div, container, js.classes.row);
        this.#renderAvailableItemsList(row1);
        this.#renderKnob(row1);
        const row1col3 = js.createElement(js.tag.div, row1, js.classes.col33);
        row1col3.innerHTML = js.html.list.replace("{{title}}", "Layers");
        this.layers = row1col3.querySelector(js.selector.list);
        this.knob.layer = kb.knob;
        this.#renderProperties(container);
 
        const newLayerEventHandler = (event) => {
            const target = event.target.closest(js.selector.listItem);
            js.makeActive(target);
            this.knob.addLayer(target.layer);
        }

        for(const key in kb.layers){
            const element = js.createElement(js.tag.div, this.available, js.classes.listItem);
            element.innerHTML = js.html.listItem.replace("{{text}}", js.titleCase(key));
            element.layer = kb.layers[key];
            const button = element.children[0];
            button.addEventListener(js.event.click, newLayerEventHandler);
            element.addEventListener(js.event.dblclick, newLayerEventHandler);
            element.addEventListener(js.event.click, () => {
                js.makeActive(element);
            });
        }

        this.knob.addEventListener(js.event.layeradded, (layerAddedEvent) => {
            const layerUsed = js.createElement(js.tag.div, this.layers, js.classes.listItem);
            layerUsed.innerHTML = js.titleCase(layerAddedEvent.detail.layer.name);
            layerUsed.layer = layerAddedEvent.detail.layer;
            layerUsed.addEventListener(js.event.click, (layerClickedEvent) => {
                if(layerUsed.classList.contains(js.classes.active)){
                    js.makeInactive(layerUsed);
                    propertiesView().render(this.knob, this.properties);
                } else{
                    js.makeActive(layerUsed);
                    propertiesView().render(layerAddedEvent.detail, this.properties);
                }
            });
        });

        this.knob.addEventListener(js.event.change, (event) => {
            this.display.innerHTML = event.value;
        });
    }

    #renderKnob(row) {
        const col = js.createElement(js.tag.div, row, js.classes.colKnob);
        col.innerHTML = js.html.card.replace("{{title}}", "Knob");
        const card = col.querySelector(js.selector.card);
        this.knob = js.createElement(js.tag.knob, card);
        this.knob.layerType = kb.knob.name;
        this.display = js.createElement(js.tag.div, card, js.classes.target);
    }
    #renderAvailableItemsList(row) {
        const col = js.createElement(js.tag.div, row, js.classes.col33);
        col.innerHTML = js.html.list.replace("{{title}}", "Available");
        this.available = col.querySelector(js.selector.list);
    }
    #renderProperties(row) {
        const col = js.createElement(js.tag.div, row, js.classes.col33);
        col.innerHTML = js.html.card.replace("{{title}}", "Properties");
        this.properties = col.querySelector(js.selector.card);
        propertiesView().render(this.knob, this.properties);
    }
}

document.addEventListener(js.event.DOMContentLoaded, () => {
    customElements.define(js.tag.maker, KnobMaker);
});
