import { js } from "./utils.js";

export const kb = {
  iterate: (ob, fn) => {
    for (const key in ob) fn(key);
  },
  appendHtmlChild: (element, htmlChildContent) => {
    element.innerHTML += htmlChildContent;
  },
  knob: {
    name: "knob",
    tag: js.tag.svg,
    attr: {
      stroke: "rgb(0,0,0)",
      fill: "rgb(255,255,255)",
      "stroke-width": 12,
    },
    style: {
      overflow: "visible",
      filter: "drop-shadow(rgb(136, 136, 136) 8px 5px 3px)",
    },
  },
  layers: {
    circle: {
      name: js.tag.circle,
      tag: js.tag.circle,
      attr: {
        cx: 0,
        cy: 0,
        r: 492,
      },
    },
    line: {
      name: js.tag.line,
      tag: js.tag.line,
      attr: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: -500,
      },
      prop: {
        minAngle: -135,
        maxAngle: 135,
        length: 0.84,
      },
    },
    "radial lines": {
      tag: js.tag.line,
      attr: {
        cx: 0,
        cy: 0,
        r: 492,
        fill: "white",
        stroke: "black",
        "stroke-width": 12,
      },
    },
    triangle: {
      tag: js.tag.polygon,
      name: "triangle",
      attr: {},
    },
    arc: {
      tag: js.tag.circle,
      attr: {
        cx: 0,
        cy: 0,
        r: 492,
        fill: "white",
        stroke: "black",
        "stroke-width": 12,
      },
    },
    rectangle: {
      tag: js.tag.rectangle,
      name: js.tag.rectangle,
      attr: {
        x: -200,
        y: -200,
        width: 400,
        height: 400,
        fill: "white",
        stroke: "black",
        "stroke-width": 12,
      },
      prop: {
        minAngle: -135,
        maxAngle: 135,
      },
    },
  },
  global: {
    svgAttributes: {
      width: "100%",
      height: "100%",
      viewBox: "-500 -500 1000 1000",
      xmlns: "http://www.w3.org/2000/svg",
      "shape-rendering": "geometricPrecision",
    },
  },
  marker: {
    mk1: `<marker id="mk1" viewBox="0 0 10 10" refX="10" refY="5" 
                markerWidth="10" markerHeight="10" orient="auto">
                <path d="M 0 0 L 10 5 L 0 10 z" fill="black" />
              </marker>`,
  },
};
