import { js } from "./utils.js";
import { kb } from "./knobster.js";
export function registerMouseEvents(target) {
    let currentStep = target.value;
    let origin;
    function setOriginFromEvent(event) {
        origin = {
            x: parseInt(event.clientX),
            y: parseInt(event.clientY)
        };
    }
    function isGoingUp(delta) { return delta < 0; }
    function isGoingDown(delta) { return delta > 0; }

    target.svgElement.addEventListener(js.event.mousedown, function (event) {
        currentStep = target.value;
        setOriginFromEvent(event);
    });
    document.addEventListener(js.event.mouseup, function (event) {
        origin = false;
    });
    document.addEventListener(js.event.mousemove, function (event) {

        function update(step) {
            step = Math.min(step, target.steps - 1);
            step = Math.max(step, 0);
            target.update(step);
            setOriginFromEvent(event);
        }
        if (origin) {
            const deltaY = event.clientY - origin.y;
            if (isGoingUp(deltaY)) {
                update(++currentStep);
            } else if (isGoingDown(deltaY)) {
                update(--currentStep);
            }
        }
    });
}
