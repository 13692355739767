export const js = {
    /**
     * Capitalises the first character of a string
     * @param {*} string - the string to capitalise
     * @returns - the capitalised string
     */
    titleCase: (string)=> {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
     /**
     * creates an HTMLElement of type tag as a child of parent
     * @param {string} tag - the type of element to create
     * @param {HTMLElement} parent - the element the new node will be made a child of
     * @param {string} classes - a classlist to apply to the new element
     * @returns the element created
     */
    createElement: (tag, parent, classes) => {
        const element = parent.appendChild(document.createElement(tag));
        if (classes) { element.className += classes; }
        return element;
    },
    /**
     * creates an SVG element of type tag as a child of parent
     * @param {String} tag - the type of element to be created
     * @param {HTMLElement} parent - the lement the new node will to be appended to 
     * @returns - the SVGElement
     */
    createSvgElement(tag, parent) {
        const svgElement = document.createElementNS("http://www.w3.org/2000/svg", tag);
        if (parent) {
            parent.appendChild(svgElement);
        }
        return svgElement;
    },
    /** 
     * removes all className (default "active") from the classLists of all siblings before adding className to target
     * @param {HTMLElement} target - the node to make active 
     * @param {String?} [className="active"] - the class name that denotes an 'active' node
     */
    makeActive: (target, className = "active") => {
        const parent = target.parentNode;
        if (parent && target) {
            for (const child of parent.children) {
                child.classList.remove(className);
            };
            target.classList.add(className);
        }
    },
    /** 
     * removes all className (default "active") from the classLists of all siblings
     * @param {HTMLElement} target - the node to make active 
     * @param {String?} [className="active"] - the class name that denotes an 'active' node
     */
    makeInactive: (target, className = "active") => {
        const parent = target.parentNode;
        if (parent && target) {
            for (const child of parent.children) {
                child.classList.remove(className);
            };
        }
    },
    /**
     * list of all the event names usewd in the app
     */
    event: {
        change: "change",
        click: "click",
        dblclick: "dblclick",
        DOMContentLoaded: "DOMContentLoaded",
        layeradded: "layeradded",
        mouseup: "mouseup",
        mousedown: "mousedown",
        mousemove: "mousemove",
        touchstart: "touchstart",
        touchmove: "touchmove",
        touchend: "touchend"
    },

    classes: {
        active: "active",
        card: "card",
        colKnob: "col-knob",
        col33: "col-33",
        container: "container",
        listItem: "kb-list-item",
        row: "row",
        target: "target",
        title: "title"
    },

    selector: {
        active: ".active",
        card: ".card",
        col33: ".col-33",
        colKnob: ".col-knob",
        container: ".container",
        list: ".kb-list",
        listItem: ".kb-list-item",
        target: ".target",
        title: ".title"
    },

    html: {
        listItem: `<button class="btn-add">+</button>&nbsp;{{text}}`,
        card: `<div class="card"><div class="card-body"><p class="card-title">{{title}}:</p></div></div>`,
        list: `<div class="card"><div class="card-body"><p class="card-title">{{title}}:</p><div class="kb-list"></div></div></div>`
    }
}


js.tag = {
    circle: "circle",
    div: "div",
    g: "g",
    input: "input",
    knob: "knobster-knob",
    line: "line",
    maker: "knobster-maker",
    polygon: "polygon",
    rectangle: "rect",
    svg: "svg"
}


export const attr = {
    id: "id",
    knob: "knob",
    length: "length",
    name: "name",
    size: "size",
    steps: "steps",
    title: "title",
    transform: "transform",
    type: "type",
    value: "value"
}

