import { js } from "./utils.js";
import { kb } from "./knobster.js";
import { attr } from "./utils.js";
import { registerMouseEvents } from "./mousehandler.js";
import { elementFactory } from "./elementfactory.js";

export default class Knob extends HTMLElement {
  svgElement;
  input;
  value;
  steps;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }
  
  connectedCallback() {
    const size = parseInt(this.getAttribute(attr.size)) || 100;
    this.#initialiseFromAtributes();
    this.#styleParentElement(size);
    this.#createHiddenInputControl();
    this.#createRootElement(size);
    registerMouseEvents(this);
    this.update(this.value);
  }

/**
 * tells the control to update its view relative to the step parameter
 * @param {integer} step - speaks for itself
 */
  update(step) {
    if (this.value !== step) {
      this.value = step;
      this.input.value = step;
      this.setAttribute(attr.value, this.value);
      for (const child of this.svgElement.children) {
        child.update(step);
      }
      const event = new Event(js.event.change, { bubbles: true, cancelable: true });
      event.value = this.value;
      this.dispatchEvent(event);
    }
  }

  /**
   * adds an SVG group element representing a layer to the knob.
   * @param {Object} layer - reference to the template fo the layer to be added
   */
  addLayer(layer) {
    const factory = new elementFactory(this);
    const element = factory.create(layer);
    element.layer = layer;

    const event = new CustomEvent(js.event.layeradded, {
      detail: element,
      bubbles: true,
      cancelable: true
    });
    this.dispatchEvent(event);
    return element;
  }

  removeLayer(layer) {
    console.log("layer removed", layer);
  }

  #initialiseFromAtributes() {
    const knobName = this.getAttribute(attr.knob) || attr.knob;
    this.value = parseInt(this.getAttribute(attr.value)) || 0;
    this.steps = parseInt(this.getAttribute(attr.steps)) || 101;
    this.knob = kb[knobName];
    this.knob.layer = kb.knob;
  }

  #styleParentElement(elementSize) {
    this.setAttribute(attr.title, "(c) knobster 2024");
    this.style.display = "inline-block";
    this.style.height = `${elementSize}px`;
    this.style.width = `${elementSize}px`;
  }

  #createHiddenInputControl() {
    this.input = document.createElement(js.tag.input)
    this.input.setAttribute(attr.name, this.getAttribute(attr.name) || attr.knob);
    this.input.setAttribute(attr.type, "hidden");
    this.input.setAttribute(attr.value, this.value);
    this.appendChild(this.input);
  }

  #createRootElement() {
    this.svgElement = document.createElementNS(kb.global.svgAttributes.xmlns, js.tag.svg);
    kb.iterate(kb.global.svgAttributes, (key) => {
      this.svgElement.setAttribute(key, kb.global.svgAttributes[key]);
    })
    this.shadowRoot.appendChild(this.svgElement);
    const defaults = kb.knob;
    Object.keys(defaults.attr).forEach(key => this.svgElement.setAttribute(key, defaults.attr[key]));
    Object.keys(defaults.style).forEach(key => this.svgElement.style.setProperty(key,defaults.style[key]));
  }

}

document.addEventListener(js.event.DOMContentLoaded, function () {
  if (customElements.get(js.tag.knob) === undefined) {
    customElements.define(js.tag.knob, Knob);
  }
});
