import { js } from "./utils.js"
import { kb } from "./knobster.js";

/**
 *  * @param {*} knob - the custom HTML parent element  
 * @returns an SVG element
 */
export function elementFactory(knob) {
    function getUpdateMethod(tag) {
        switch (tag.toLowerCase()) {
            case js.tag.line: return function (step) {
                step = step || 0;
                const line = this.querySelector(js.tag.line);
                const degreesPerStep = (line.maxAngle - line.minAngle) / (knob.steps - 1);
                const angle = line.minAngle + (degreesPerStep * step);
                const radians = ((angle - 90) * Math.PI) / 180;
                line.setAttribute("x2", (Math.cos(radians) * 500 * line.length));
                line.setAttribute("y2", (Math.sin(radians) * 500 * line.length));
            }
            case js.tag.rectangle: return function (step) {
                step = step || 0;
                const rect = this.querySelector(js.tag.rectangle);
                const degreesPerStep = (rect.maxAngle - rect.minAngle) / (knob.steps - 1);
                const angle = rect.minAngle + (degreesPerStep * step);
                this.setAttribute("transform", `rotate(${angle})`);
            }
            default: return function (step) { }
        }
    }

    /**
     * @param {*} layer - a template for creating the element 
     * @returns 
     */
    function create(layer) {
        const group = js.createSvgElement(js.tag.g, knob.svgElement);
        const element = js.createSvgElement(layer.tag, group);
        for (const key in layer.attr) {
            element.setAttribute(key, layer.attr[key]);
        }
        for (const key in layer.prop) {
            element[key] = layer.prop[key];
        }
        group.update = getUpdateMethod(layer.name);
        return group;
    }
    return {
        create: create
    };
}